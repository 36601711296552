export function getAllCapacity(eventType, reservations) {
    const mainReservations = reservations.filter(res => res.pewNum <= eventType.numPews);
    const mainCapacity = getSeatingAreaCapacity(eventType.numPews, mainReservations);

    const downstairsReservations = reservations.filter(res => res.pewNum > eventType.numPews);
    const downstairsCapacity = getSeatingAreaCapacity(eventType.downstairsNumPews, downstairsReservations, eventType.numPews);

    return {
        main: mainCapacity,
        downstairs: downstairsCapacity
    };
}

export function getSeatingAreaCapacity(numPews, reservations, pewNumOffset = 0) {
    let pews = [];
    for (let index = 0; index < numPews; index++) {
        pews.push(0);
    }

    const BUFFER_SEATS = 1;
    let totalPeople = 0;

    reservations.forEach(res => {
        totalPeople += res.numPeople;
        pews[res.pewNum - 1 - pewNumOffset] += (res.numPeople + BUFFER_SEATS);
    });

    return { totalPeople, pews };
}

export function hasCapacity(eventType, eventCapacity, numberOfPeople, downstairsOk) {
    return !!findAndAssignPews(eventType, eventCapacity, numberOfPeople, downstairsOk);
}

export function findAndAssignPews(eventType, eventCapacity, numberOfPeople, downstairsOk) {
    let assignments = assignPews(eventType.maxPeople, eventCapacity.main, numberOfPeople);

    if (!assignments && downstairsOk) {
        let pewNumOffset = eventType.numPews;
        assignments = assignPews(eventType.downstairsMaxPeople, eventCapacity.downstairs, numberOfPeople, pewNumOffset)
    }

    return assignments;
}

export function assignPews(maxPeople, eventCapacity, numberOfPeople, pewNumOffset = 0) {
    const totalPeopleAfter = eventCapacity.totalPeople + numberOfPeople;
    if (totalPeopleAfter > maxPeople) {
        return false;
    }

    const seatingGroups = getSeatingGroups(numberOfPeople);
    const allPews = eventCapacity.pews;

    const MAX_PEOPLE_PER_PEW = 5;

    let takenPewIndices = [];
    allPews.forEach((numTaken, idx) => {
        if (numTaken >= MAX_PEOPLE_PER_PEW) {
            takenPewIndices.push(idx);
        }
    });

    let availablePewCount = allPews.length - takenPewIndices.length;
    if (availablePewCount < seatingGroups.length) {
        return false;
    }

    let assignments = [];
    seatingGroups.forEach(sg => {
        let assignCallback = (foundIndex) => {
            assignments.push({ pewNum: foundIndex + 1 + pewNumOffset, numPeople: sg, totalPeople: numberOfPeople });
            takenPewIndices.push(foundIndex);
        };

        let targetPewByNumPeople = MAX_PEOPLE_PER_PEW - sg;

        while (targetPewByNumPeople >= 0) {
            if (getAvailablePewIndex(allPews, targetPewByNumPeople, takenPewIndices, assignCallback)) {
                break;
            }
            targetPewByNumPeople--;
        }
    });

    if (assignments.length != seatingGroups.length) {
        return false;
    }

    return assignments;
}

const getSeatingGroups = (numberOfPeople) => {
    let groups = [];
    let remainingPeople = numberOfPeople;

    while (remainingPeople > 5) {
        groups.push(5);
        remainingPeople -= 5;
    }

    if (remainingPeople > 0) {
        groups.push(remainingPeople);
    }

    return groups;
};

const getAvailablePewIndex = (pews, matchingNumPeople, takenPewIndices, foundCallback) => {
    let foundIndex = pews.findIndex((numTaken, idx) => numTaken == matchingNumPeople && takenPewIndices.indexOf(idx) == -1);
    if (foundIndex > -1) {
        foundCallback(foundIndex);
        return true;
    }
    return false;
};