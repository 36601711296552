<template>
  <div>
    <h1 class="title has-text-grey">Select A Date</h1>
    <hr />
    <article v-if="multiSelectEnabled" class="message is-info">
      <div class="message-body">
        You can make multiple selections. To remove a selection click on the button again.
      </div>
    </article>
    <div class="box">
      <div v-if="isLoading">Loading events..</div>
      <div v-else-if="events.length == 0">No events to display.</div>
      <div v-else>
        <div class="media" v-for="(item, index) in events" :key="index">
          <div class="media-content">
            <p>
              <strong>{{ formatDate(item.date) }}</strong>
              <br v-if="item.title" />
              <span v-if="item.title">{{ item.title }}</span>
              <br />
              {{ item.description }}
            </p>
          </div>
          <div class="media-right">
            <button v-if="!hasEnoughCapacity(item)" class="button is-rounded is-static">No Availability</button>
            <button v-else-if="isSelected(item)" class="button is-rounded is-success is-light" @click="$emit('eventdeselect', item)">SELECTED</button>
            <button v-else class="button is-rounded" @click="$emit('eventselect', item)">Select</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from "../mixins";
import { getEvents } from "../service/data";
import { hasCapacity } from "../service/capacity";

export default {
  name: "EventSelection",

  mixins: [mixins],

  data() {
    return {
      events: [],
      isLoading: true
    };
  },

  props: {
    selectedEventType: Object,
    selectedEvents: Array,
    numberOfPeople: Number,
    downstairsOk: Boolean,
    multiSelectEnabled: { type: Boolean, default: false }
  },

  mounted() {
    getEvents(this.selectedEventType).then(result => {
      this.events = result;
      this.isLoading = false;
    });
  },

  methods: {
    hasEnoughCapacity(event) {
      return hasCapacity(this.selectedEventType, event.capacity, this.numberOfPeople, this.downstairsOk);
    },

    isSelected(event) {
      if (!this.selectedEvents || this.selectedEvents.length == 0) {
        return false;
      }

      const idx = this.selectedEvents.findIndex(e => e.id == event.id);
      return idx > -1;
    }
  }
};
</script>