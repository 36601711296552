import VueRouter from 'vue-router';

import ChurchSignup from './components/ChurchSignup.vue';
import VolunteerSignup from './components/VolunteerSignup.vue';
import KidsClubSignup from './components/KidsClubSignup.vue';

const routes = [
    { path: '', redirect: '/church' },
    { path: '/church', component: ChurchSignup },
    { path: '/volunteer/:eventId', component: VolunteerSignup },
    { path: '/kidsclub/:eventTypeId', component: KidsClubSignup },
];

const router = new VueRouter({
    mode: 'history',
    routes
});

export default router;