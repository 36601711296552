<template>
  <div>
    <h1 class="title has-text-grey">Your Information</h1>
    <div class="field">
      <label class="label">First Name</label>
      <div class="control">
        <input
          class="input"
          :class="{ 'is-danger': errors.indexOf('firstName') > -1}"
          v-model="familyInfo.firstName"
          type="text"
          placeholder="Enter first name"
        />
      </div>
      <p v-if="errors.indexOf('firstName') > -1" class="help is-danger">Please enter a first name.</p>
    </div>
    <div class="field">
      <label class="label">Last Name</label>
      <div class="control">
        <input
          class="input"
          :class="{ 'is-danger': errors.indexOf('lastName') > -1}"
          v-model="familyInfo.lastName"
          type="text"
          placeholder="Enter last name"
        />
      </div>
      <p v-if="errors.indexOf('lastName') > -1" class="help is-danger">Please enter a last name.</p>
    </div>
    <div class="field">
      <label class="label">Phone</label>
      <div class="control">
        <masked-input
          class="input"
          :class="{ 'is-danger': errors.indexOf('phone') > -1}"
          v-model="familyInfo.phone"
          type="text"
          placeholder="Enter phone"
          :mask="['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]"
          :guide="false"
        />
      </div>
      <p
        v-if="errors.indexOf('phone') > -1"
        class="help is-danger"
      >Please enter a valid phone number.</p>
    </div>
    <div class="field">
      <label class="label">Number of people (including yourself)</label>
      <div class="control">
        <input
          class="input"
          :class="{ 'is-danger': errors.indexOf('numberOfPeople') > -1}"
          v-model.number="familyInfo.numberOfPeople"
          type="number"
        />
      </div>
      <p
        v-if="errors.indexOf('numberOfPeople') > -1"
        class="help is-danger"
      >Please enter a number greater than zero.</p>
    </div>
    <div v-if="false" class="field">
      <label class="label" :class="{'has-text-danger': errors.indexOf('downstairsOk') > -1}">
        Are you okay with being seated downstairs if no space is available in the church?
        <br />هل تقبل الجلوس في القاعة السفلية في حالة عدم توفر أماكن في الكنيسة؟
      </label>
      <div class="control">
        <div class="buttons">
          <button
            @click="onDownstairsSelection(true)"
            class="button is-rounded"
            :class="{'is-link': familyInfo.downstairsOk === true}"
          >Yes</button>
          <button
            @click="onDownstairsSelection(false)"
            class="button is-rounded"
            :class="{'is-link': familyInfo.downstairsOk === false}"
          >No</button>
        </div>
        <p
          v-if="errors.indexOf('downstairsOk') > -1"
          class="help is-danger"
        >Please select yes or no.</p>
      </div>
    </div>
  </div>
</template>

<script>
import MaskedInput from "vue-text-mask";

export default {
  name: "FamilyInfo",

  components: {
    MaskedInput
  },

  props: {
    familyInfo: Object
  },

  data() {
    return {
      errors: []
    };
  },

  watch: {
    "familyInfo.firstName": function() {
      this.validateFirstName();
    },
    "familyInfo.lastName": function() {
      this.validateLastName();
    },
    "familyInfo.phone": function() {
      this.validatePhone();
    },
    "familyInfo.numberOfPeople": function() {
      this.validateNumberOfPeople();
    }
  },

  methods: {
    onDownstairsSelection(value) {
      this.clearPropError('downstairsOk');
      this.$emit('downstairsselectionchange', value);
    },

    clearPropError(propName) {
      let idx = this.errors.indexOf(propName);
      if (idx > -1) {
        this.errors.splice(idx, 1);
      }
    },

    validateProp(propName, validateFn) {
      this.clearPropError(propName);

      if (!validateFn(this.familyInfo[propName])) {
        this.errors.push(propName);
      }
    },

    validateFirstName() {
      this.validateProp("firstName", val => {
        return val != null && val.trim().length > 0;
      });
    },

    validateLastName() {
      this.validateProp("lastName", val => {
        return val != null && val.trim().length > 0;
      });
    },

    validatePhone() {
      this.validateProp("phone", val => {
        if (val == null || val.trim().length == 0) return false;

        const pattern = /^\d{10}$/;
        let transformedValue = val.replace(/[^\d]/g, "");
        return pattern.test(transformedValue);
      });
    },

    validateNumberOfPeople() {
      this.validateProp("numberOfPeople", val => {
        return val > 0;
      });
    },

    validateDownstairsSelection() {
      this.validateProp("downstairsOk", val => {
        return val != null;
      });
    },

    validateAll() {
      this.validateFirstName();
      this.validateLastName();
      this.validatePhone();
      this.validateNumberOfPeople();
      this.validateDownstairsSelection();

      return this.errors.length == 0;
    }
  }
};
</script>