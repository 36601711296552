<template>
  <div>
    <header-section subtitle="Kids Club Signup"></header-section>

    <div v-if="!isLoading" class="container">
      <p class="title is-1">
        {{ eventTypeDetails.title || $route.params.eventTypeId }}
      </p>
      <table class="table is-bordered">
        <tbody>
          <tr>
            <th>Price</th>
            <td><strong>${{ eventTypeDetails.priceInfo.amount }}</strong> ({{ eventTypeDetails.priceInfo.description }})</td>
          </tr>
          <tr>
            <th>Payment Link</th>
            <td><a :href="eventTypeDetails.paymentLink" target="_blank">{{ eventTypeDetails.paymentLink }}</a></td>
          </tr>
        </tbody>
      </table>

      <div v-if="!succeeded">
        <kids-club-family-info
          :familyInfoObj="familyInfo.contact"
          @familyinfochange="updateFamilyInfo"
        />

        <kids-club-event-list
          :selectedEvents="familyInfo.selections"
          @selectionchange="updateSelections"
        />

        <button
          class="button is-info is-large"
          @click="openReviewModal"
          :disabled="
            !isContactInfoValid || familyInfo.selections.length === 0
          "
        >
          Continue
        </button>

        <div v-if="showPreview" class="modal is-active">
          <div class="modal-background"></div>
          <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">Review your selections</p>
              <button
                @click="closeModal"
                class="delete"
                aria-label="close"
              ></button>
            </header>
            <section class="modal-card-body" ref="modalBody">
              <article v-if="failed" class="message is-danger">
                <div class="message-body">
                  There was a problem completing your sign up.
                </div>
              </article>

              <kids-club-confirmation :familyInfo="familyInfo" />

            </section>
            <footer class="modal-card-foot modal-buttons">
              <button @click="closeModal" class="button">Cancel</button>

              <button
                @click="handleSignup"
                class="button is-success"
                :class="{ 'is-loading': processing }"
                :disabled="processing"
              >
                Complete Signup
              </button>
            </footer>
          </div>
        </div>
      </div>

      <div v-else-if="succeeded">
        <p class="title is-3 has-text-success">You are signed up!</p>

        <article class="message is-info">
          <div class="message-body">
            We encourage you to print or take a screenshot of the information
            below for your records.
          </div>
        </article>

        <p class="title is-4 has-text-link">Your total: <strong>${{ totalCost }}</strong></p>

        <kids-club-confirmation :familyInfo="familyInfo" />
      </div>
    </div>
  </div>
</template>

<script>
import { getEventTypeDetails, signUp } from "../service/kidsClubData";
import { logError } from "../service/logger";

import HeaderSection from "./Header.vue";
import KidsClubFamilyInfo from "./KidsClubFamilyInfo.vue";
import KidsClubEventList from "./KidsClubEventList.vue";
import KidsClubConfirmation from "./KidsClubConfirmation.vue";

import mixins from "../mixins";

export default {
  name: "KidsClubSignup",

  mixins: [mixins],

  components: {
    HeaderSection,
    KidsClubFamilyInfo,
    KidsClubEventList,
    KidsClubConfirmation,
  },

  props: {},

  data() {
    return {
      familyInfo: {
        contact: {
          firstName: "",
          lastName: "",
          phone: "",
          numberOfAdults: 0,
          numberOfChildren: 1,
          hasErrors: false,
        },
        selections: [],
      },
      eventTypeDetails: {},
      isLoading: true,
      showPreview: false,
      processing: false,
      failed: false,
      succeeded: false,
    };
  },

  computed: {
    eventTitle() {
      return this.eventTypeDetails.title || this.$route.params.eventTypeId;
    },

    isContactInfoValid() {
      return (
        this.familyInfo.contact.firstName.length > 0 &&
        this.familyInfo.contact.lastName.length > 0 &&
        this.familyInfo.contact.phone.length > 0 &&
        !this.familyInfo.contact.hasErrors
      );
    },

    totalCost() {
      return this.eventTypeDetails.priceInfo.amount * this.familyInfo.contact.numberOfChildren * this.familyInfo.selections.length;
    }
  },

  mounted() {
    getEventTypeDetails(this.$route.params.eventTypeId)
      .then((result) => {
        this.eventTypeDetails = result;
        this.isLoading = false;
      })
      .catch((err) => logError("getEventTypeDetails Failed:", err));
  },

  methods: {
    updateFamilyInfo(propName, value, hasErrors) {
      this.familyInfo.contact[propName] = value;
      this.familyInfo.contact.hasErrors = hasErrors;
    },

    updateSelections(selections) {
      this.familyInfo.selections = selections;
      this.sortSelectionsByDate();
    },

    sortSelectionsByDate() {
      const sortByDateFn = (a, b) => {
        if (a.start < b.start) {
          return -1;
        }
        if (a.start > b.start) {
          return 1;
        }
        return 0;
      };
      this.familyInfo.selections.sort(sortByDateFn);
    },

    openReviewModal() {
      this.failed = false;
      this.showPreview = true;
    },

    closeModal() {
      this.failed = false;
      this.showPreview = false;
    },

    handleSignup() {
      this.processing = true;

      signUp(this.familyInfo)
        .then(this.handleSuccess)
        .catch(this.handleFailure);
    },

    handleFailure(err) {
      logError(err);
      this.failed = true;
      this.processing = false;
      this.$refs.modalBody.scrollTop = 0;
    },

    handleSuccess() {
      this.succeeded = true;
      this.processing = false;
      this.showPreview = false;
    },
  },
};
</script>

<style scoped>
.container {
  margin-bottom: 25px;
}
.modal-buttons {
  justify-content: space-between;
}
</style>