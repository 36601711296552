import firebase from "firebase/compat/app";

import 'firebase/compat/database';
import moment from 'moment';

import { getAllCapacity } from './capacity';

let dbUrl = '';

if (process.env.NODE_ENV === 'development') {
    dbUrl = "https://amcsv-test.firebaseio.com";
} else {
    dbUrl = "https://amcsv-2c3f2.firebaseio.com";
}

const firebaseConfig = {
    apiKey: "AIzaSyCTpudld5U4OxDyP5MPdg-JKzkm0oYlaII",
    authDomain: "amcsv-2c3f2.firebaseapp.com",
    databaseURL: dbUrl,
    projectId: "amcsv-2c3f2",
    storageBucket: "amcsv-2c3f2.appspot.com",
    messagingSenderId: "683832146174",
    appId: "1:683832146174:web:a5908efc22022fcccbcdaf"
};

firebase.initializeApp(firebaseConfig);

const database = firebase.database();

export function getEventTypes() {
    let eventTypesRef = database.ref('event_types');
    return eventTypesRef
        .orderByChild('active')
        .startAt(true)
        .once('value')
        .then((snapshot) => {
            let eventTypes = [];
            snapshot.forEach(childSnapshot => {
                let eventTypeObj = childSnapshot.val();
                eventTypeObj.id = childSnapshot.key;
                eventTypes.push(eventTypeObj);
            });
            return eventTypes;
        });
}

export function getEvents(eventType) {
    // const start_date = moment().add(1, 'day').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISOString();
    const start_date = moment().toISOString();
    const end_date = moment().add(60, 'day').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISOString();

    let eventsRef = database.ref('events');
    return eventsRef
        .orderByChild('date')
        .startAt(start_date)
        .endAt(end_date)
        .once('value')
        .then((snapshot) => {
            let events = [];
            let promises = [];
            snapshot.forEach(childSnapshot => {
                let eventObj = childSnapshot.val();
                eventObj.id = childSnapshot.key;

                if (eventObj.type == eventType.id) {
                    events.push(eventObj);

                    let promise = getEventCapacity(eventObj.id, eventType)
                        .then(capacity => {
                            eventObj.capacity = capacity;
                        });
                    promises.push(promise);
                }
            });
            return Promise.all(promises).then(() => events);
        });
}

export function getEventCapacity(eventId, eventType) {
    return getReservations(eventId)
        .then(reservations => {
            return getAllCapacity(eventType, reservations);
        });
}

export function getReservations(eventId) {
    let reservationsRef = database.ref(`reservations/${eventId}`);
    return reservationsRef
        .once('value')
        .then((snapshot) => {
            let reservations = [];
            snapshot.forEach(childSnapshot => {
                let resObj = childSnapshot.val();
                resObj.id = childSnapshot.key;
                reservations.push(resObj);
            });
            return reservations;
        });
}

export function saveReservation(event, familyInfo, pewAssignments) {
    const eventId = event.id;
    return getOrAddFamilyId(familyInfo)
        .then(familyId => {
            return addReservations(eventId, familyId, pewAssignments);
        });
}

const addReservations = (eventId, familyId, pewAssignments) => {
    const reservationsRef = database.ref(`reservations/${eventId}`);

    let promises = [];

    pewAssignments.forEach(assignment => {
        let resObj = {
            familyId,
            pewNum: assignment.pewNum,
            numPeople: assignment.numPeople,
            totalPeople: assignment.totalPeople,
            timestamp: moment().toISOString()
        };

        let promise = reservationsRef.push(resObj).then(res => res.key);
        promises.push(promise);
    });

    return Promise.all(promises);
};

const getOrAddFamilyId = (familyInfo) => {
    const phoneNumsOnly = familyInfo.phone.replace(/[^\d]/g, '');
    return getPhoneIndex(phoneNumsOnly)
        .then(familyId => {
            if (familyId == null) {
                return addFamily(familyInfo).then(id => {
                    return addPhoneIndex(phoneNumsOnly, id).then(() => id);
                });
            } else {
                return familyId;
            }
        });
};

const getPhoneIndex = (normalizedPhoneNum) => {
    let phoneIndexRef = database.ref(`phone_index/${normalizedPhoneNum}`);
    return phoneIndexRef
        .once('value')
        .then(piSnapshot => piSnapshot.val());
};

const addPhoneIndex = (normalizedPhoneNum, familyId) => {
    let phoneIndexRef = database.ref(`phone_index/${normalizedPhoneNum}`);
    return phoneIndexRef.set(familyId);
};

const addFamily = (familyInfo) => {
    const fullName = `${familyInfo.firstName} ${familyInfo.lastName}`;
    let familiesRef = database.ref('families');
    return familiesRef
        .push({ phone: familyInfo.phone, name: fullName })
        .then(familiesRes => familiesRes.key);
};