<template>
  <div class="selections-container">
    <p class="title is-3 has-text-grey">Selected Volunteer Jobs</p>

    <div>
      <div v-for="item in selections" :key="item.id">
        <div class="columns box">
            <div class="column"><strong>{{ formatDay(item.slotData.start) }}</strong></div>
            <div class="column">{{ formatTime(item.slotData.start) }} - {{ formatTime(item.slotData.end) }}</div>
            <div class="column">{{ item.jobTitle }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from "../mixins";

export default {
  name: "VolunteerSelections",

  mixins: [mixins],

  components: {},

  props: {
    selections: Array,
  },

  computed: {},

  methods: {},
};
</script>

<style scoped>
.selections-container {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>