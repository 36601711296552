<template>
  <div class="confirmation-container">
    <read-only-field label="Name" :value="fullName" />
    <read-only-field label="Phone" :value="familyInfo.contact.phone" />
    <read-only-field
      label="Number of People"
      :value="numberOfPeople"
    />

    <read-only-field label="Selected Days" :values="selectedDates" />
  </div>
</template>

<script>
import ReadOnlyField from "./ReadOnlyField.vue";

import mixins from "../mixins";

export default {
  name: "KidsClubConfirmation",

  mixins: [mixins],

  components: {
    ReadOnlyField,
  },

  props: {
    familyInfo: Object,
  },

  computed: {
    fullName() {
      return `${this.familyInfo.contact.firstName} ${this.familyInfo.contact.lastName}`;
    },

    numberOfPeople() {
      const numAdults = this.familyInfo.contact.numberOfAdults;
      const numChildren = this.familyInfo.contact.numberOfChildren;

      const list = [];
      
      if (numAdults > 0) {
        list.push(numAdults);
        if (numAdults > 1) {
          list.push(' adults, ');
        } else {
          list.push(' adult, ');
        }
      }
      
      list.push(numChildren);
      if (numChildren > 1) {
        list.push(' children');
      } else {
        list.push(' child');
      }

      return list.join('');
    },

    selectedDates() {
      return this.familyInfo.selections.map(e => `${this.formatDay(e.start)}, ${this.formatTime(e.start)} - ${this.formatTime(e.end)}`);
    },
  }
};
</script>

<style scoped>
</style>