<template>
  <div>
    <header-section subtitle="Volunteer Signup"></header-section>

    <div v-if="!isLoading" class="container">
      <p class="title is-1">
        {{ this.eventDetails.title || this.$route.params.eventId }}
      </p>

      <div v-if="!succeeded">
        <volunteer-contact
          :volunteerContact="volunteerData.contact"
          @contactinfochange="updateContactInfo"
        />

        <volunteer-jobs-list
          :selections="volunteerData.selections"
          @selectionchange="updateSelections"
        />

        <button
          class="button is-info is-large"
          @click="openReviewModal"
          :disabled="
            !isContactInfoValid || volunteerData.selections.length === 0
          "
        >
          Continue
        </button>

        <div v-if="showPreview" class="modal is-active">
          <div class="modal-background"></div>
          <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">Review your selections</p>
              <button
                @click="closeModal"
                class="delete"
                aria-label="close"
              ></button>
            </header>
            <section class="modal-card-body" ref="modalBody">
              <article v-if="failed" class="message is-danger">
                <div class="message-body">
                  There was a problem completing your sign up. You may click
                  Cancel and try again.
                </div>
              </article>

              <p class="title is-3 has-text-grey">Contact Info</p>

              <read-only-field label="Name" :value="fullName" />
              <read-only-field
                label="Phone"
                :value="volunteerData.contact.phone"
              />

              <hr />

              <volunteer-selections :selections="volunteerData.selections" />
            </section>
            <footer class="modal-card-foot modal-buttons">
              <button @click="closeModal" class="button">Cancel</button>

              <button
                @click="handleSignup"
                class="button is-success"
                :class="{ 'is-loading': processing }"
                :disabled="processing"
              >
                Complete Signup
              </button>
            </footer>
          </div>
        </div>
      </div>

      <div v-else-if="succeeded">
        <p class="title is-3 has-text-success">Thank you for volunteering!</p>

        <article class="message is-info">
          <div class="message-body">
            We encourage you to print or take a screenshot of the information
            below for your records.
          </div>
        </article>

        <volunteer-selections :selections="selectionsSnapshot" />
      </div>
    </div>
  </div>
</template>

<script>
import { getEventDetails, signUp } from "../service/volunteerData";
import { logError } from "../service/logger";

import HeaderSection from "./Header.vue";
import VolunteerContact from "./VolunteerContact.vue";
import VolunteerJobsList from "./VolunteerJobsList.vue";
import VolunteerSelections from "./VolunteerSelections.vue";
import ReadOnlyField from "./ReadOnlyField.vue";

export default {
  name: "VolunteerSignup",

  components: {
    HeaderSection,
    VolunteerContact,
    VolunteerJobsList,
    VolunteerSelections,
    ReadOnlyField,
  },

  props: {},

  data() {
    return {
      volunteerData: {
        contact: {
          firstName: "",
          lastName: "",
          phone: "",
          hasErrors: false,
        },
        selections: [],
      },
      eventDetails: {},
      isLoading: true,
      showPreview: false,
      processing: false,
      failed: false,
      succeeded: false,
      selectionsSnapshot: [],
    };
  },

  computed: {
    eventTitle() {
      return this.eventDetails.title || this.$route.params.eventId;
    },

    isContactInfoValid() {
      return (
        this.volunteerData.contact.firstName.length > 0 &&
        this.volunteerData.contact.lastName.length > 0 &&
        this.volunteerData.contact.phone.length > 0 &&
        !this.volunteerData.contact.hasErrors
      );
    },

    fullName() {
      return `${this.volunteerData.contact.firstName} ${this.volunteerData.contact.lastName}`;
    },
  },

  mounted() {
    getEventDetails(this.$route.params.eventId)
      .then((result) => {
        this.eventDetails = result;
        this.isLoading = false;
      })
      .catch((err) => logError("getEventDetails Failed:", err));
  },

  methods: {
    updateContactInfo(propName, value, hasErrors) {
      this.volunteerData.contact[propName] = value;
      this.volunteerData.contact.hasErrors = hasErrors;
    },

    updateSelections(payload, selected) {
      let existingIndex = this.volunteerData.selections.findIndex(
        (s) => s.jobId === payload.jobId && s.slotId === payload.slotId
      );
      if (selected && existingIndex == -1) {
        this.volunteerData.selections.push(payload);
      } else if (!selected && existingIndex > -1) {
        this.volunteerData.selections.splice(existingIndex, 1);
      }

      this.sortSelectionsByDate();
    },

    sortSelectionsByDate() {
      const sortByDateFn = (a, b) => {
        if (a.slotData.start < b.slotData.start) {
          return -1;
        }
        if (a.slotData.start > b.slotData.start) {
          return 1;
        }
        return 0;
      };
      this.volunteerData.selections.sort(sortByDateFn);
    },

    openReviewModal() {
      this.failed = false;
      this.showPreview = true;
    },

    closeModal() {
      this.failed = false;
      this.showPreview = false;
    },

    handleSignup() {
      this.processing = true;
      // save selections before update so that post signup changes aren't reflected
      this.selectionsSnapshot = [...this.volunteerData.selections];

      signUp(this.volunteerData)
        .then(this.handleSuccess)
        .catch(this.handleFailure);
    },

    handleFailure(err) {
      logError(err);
      this.failed = true;
      this.processing = false;
      this.$refs.modalBody.scrollTop = 0;
    },

    handleSuccess() {
      this.succeeded = true;
      this.processing = false;
      this.showPreview = false;
    },
  },
};
</script>

<style scoped>
.container {
  margin-bottom: 25px;
}
.modal-buttons {
  justify-content: space-between;
}
</style>