<template>
  <div class="panel is-info">
    <div class="panel-heading">{{ jobDate }}</div>
    <div class="panel-block">
        <p><strong>{{ jobStart }} - {{ jobEnd }}</strong></p>
    </div>
    <div class="panel-block">
        <p class="title is-6 has-text-dark" v-if="remainingCount == 0">FULL</p>
        <label v-else-if="remainingCount > 0" class="checkbox">
            <input type="checkbox" v-model="selected">Sign up
        </label>
    </div>
    <div class="panel-block">
        <button class="button is-info is-outlined is-fullwidth is-static">
            {{remainingCount}}/{{slotData.count}} remaining
        </button>
    </div>
  </div>
</template>

<script>
import mixins from "../mixins";
import { registerVolunteerCountListener, deregisterVolunteerCountListener } from "../service/volunteerData";

export default {
  name: "VolunteerJob",

  mixins: [mixins],

  components: {},

  props: {
    jobId: String,
    jobTitle: String,
    slotId: String,
    slotData: Object,
    isSelected: Boolean
  },

  data() {
    return {
      filledCount: 0,
      selected: this.isSelected
    };
  },

   watch: {
    "selected": function() {
      this.notifySelection();
    }
  },

  computed: {
    jobDate() {
      return this.formatDay(this.slotData.start);
    },
    jobStart() {
      return this.formatTime(this.slotData.start);
    },
    jobEnd() {
      return this.formatTime(this.slotData.end);
    },
    remainingCount() {
        return `${this.slotData.count - this.filledCount}`;
    }
  },

  mounted() {
    registerVolunteerCountListener(this.jobId, this.slotId, this.updateFilledCount);
  },

  methods: {
    updateFilledCount(count) {
      this.filledCount = count;
      if (this.filledCount === this.slotData.count) {
          this.selected = false;
      }
    },

    notifySelection() {
        const payload = {
            jobId: this.jobId,
            jobTitle: this.jobTitle,
            slotId: this.slotId,
            slotData: this.slotData
        };
        // TODO improve this 
        this.$parent.$emit('selectionchange', payload, this.selected);
    }
  },

  beforeDestroy() {
    deregisterVolunteerCountListener(this.jobId, this.slotId);
  }
};
</script>
