<template>
  <div :style="{ 'margin-top': '20px' }">
    <h1 class="title is-6">I would like to sign up for:</h1>
    <div class="buttons">
      <button
        v-for="eventType in eventTypes"
        v-bind:key="eventType.id"
        @click="$emit('eventtypechange', eventType)"
        class="button is-rounded"
        v-bind:class="{'is-link': selectedEventType != null && eventType.id == selectedEventType.id}"
      >{{ eventType.name }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "EventTypeSelection",
  props: {
    eventTypes: Array,
    selectedEventType: Object
  }
};
</script>