import {
    getEventCapacity,
    saveReservation
} from "./data";
import { findAndAssignPews } from "./capacity";

export const NO_CAPACITY_ERROR = 'No capacity';

export function finalizeReservation(selectedEvent, selectedEventType, familyInfo) {
    return getEventCapacity(selectedEvent.id, selectedEventType)
        .then(eventCapacity => {
            let pewAssignments = findAndAssignPews(
                selectedEventType,
                eventCapacity,
                familyInfo.numberOfPeople,
                familyInfo.downstairsOk
            );

            if (pewAssignments == false) {
                return Promise.reject(NO_CAPACITY_ERROR);
            } else {
                return saveReservation(selectedEvent, familyInfo, pewAssignments);
            }
        });
}
