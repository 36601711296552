import { initializeApp } from 'firebase/app';
import { getDatabase, ref, query, orderByChild, equalTo, onValue, push, update, serverTimestamp } from "firebase/database";

import { log } from './logger';

let dbUrl = '';

if (process.env.NODE_ENV === 'development') {
    dbUrl = "https://amcsv-test.firebaseio.com";
} else {
    dbUrl = "https://amcsv-2c3f2.firebaseio.com";
}

const firebaseConfig = {
    apiKey: "AIzaSyCTpudld5U4OxDyP5MPdg-JKzkm0oYlaII",
    authDomain: "amcsv-2c3f2.firebaseapp.com",
    databaseURL: dbUrl,
    projectId: "amcsv-2c3f2",
    storageBucket: "amcsv-2c3f2.appspot.com",
    messagingSenderId: "683832146174",
    appId: "1:683832146174:web:a5908efc22022fcccbcdaf"
};

const app = initializeApp(firebaseConfig);

const database = getDatabase(app);

export function getEventTypeDetails(eventTypeId) {
    const dbPath = `kids_club_event_types/${eventTypeId}`;
    const dbRef = ref(database, dbPath);

    return new Promise((resolve, reject) => {
        const callback = (snapshot) => {
            const obj = snapshot.val();
            obj.id = snapshot.key;
            resolve(obj);
        };

        const errorCallback = (error) => (reject(error));

        const options = { onlyOnce: true };

        onValue(dbRef, callback, errorCallback, options);
    });
}

export function getEvents(eventTypeId) {
    const dbPath = 'kids_club_events';
    const dbRef = query(ref(database, dbPath), orderByChild('type'), equalTo(eventTypeId));

    return new Promise((resolve, reject) => {
        const callback = (snapshot) => {
            let objList = [];
            snapshot.forEach(childSnapshot => {
                let childObj = childSnapshot.val();
                childObj.id = childSnapshot.key;
                objList.push(childObj);
            });
            resolve(objList);
        };

        const errorCallback = (error) => (reject(error));

        const options = { onlyOnce: true };

        onValue(dbRef, callback, errorCallback, options);
    });
}

export function signUp(data) {
    const {contact:familyInfo, selections} = data;

    const updates = {};
    selections.forEach(sel => {
        let dbPath = `kids_club_reservations/${sel.id}`;
        let newRef = push(ref(database, dbPath));
        let payload = {
            firstName: familyInfo.firstName,
            lastName: familyInfo.lastName,
            phone: familyInfo.phone,
            numberOfAdults: familyInfo.numberOfAdults,
            numberOfChildren: familyInfo.numberOfChildren,
            timestamp: serverTimestamp()
        };
        updates[`${dbPath}/${newRef.key}`] = payload;
    });
    log('Sending updates..');

    return update(ref(database), updates);
}

/* helpers */

// const getOrAddVolunteerId = (volunteerInfo) => {
//     const phoneNumsOnly = volunteerInfo.phone.replace(/[^\d]/g, '');
//     return getPhoneIndex(phoneNumsOnly)
//         .then(volunteerId => {
//             if (volunteerId == null) {
//                 return addVolunteer(volunteerInfo).then(id => {
//                     return addPhoneIndex(phoneNumsOnly, id).then(() => id);
//                 });
//             } else {
//                 return volunteerId;
//             }
//         });
// };

// const getPhoneIndex = (normalizedPhoneNum) => {
//     log('Checking phone index..');
//     const phoneIndexRef = ref(database, `volunteer_phone_index/${normalizedPhoneNum}`);

//     return new Promise((resolve, reject) => {
//         const callback = piSnapshot => resolve(piSnapshot.val());

//         const options = { onlyOnce: true };

//         onValue(phoneIndexRef, callback, (err) => reject(err), options);
//     });
// };

// const addPhoneIndex = (normalizedPhoneNum, volunteerId) => {
//     log('Adding phone index..');
//     const phoneIndexRef = ref(database, `volunteer_phone_index/${normalizedPhoneNum}`);
//     return set(phoneIndexRef, volunteerId);
// };

// const addVolunteer = (volunteerInfo) => {
//     log('Adding volunteer..');
//     const fullName = `${volunteerInfo.firstName} ${volunteerInfo.lastName}`;
//     const volunteersRef = ref(database, 'volunteers');
//     return push(volunteersRef, { phone: volunteerInfo.phone, name: fullName })
//         .then(volSnapshot => volSnapshot.key);
// };
