<template>
  <div>
    <p class="title is-3 has-text-grey">Contact Info</p>
    <div class="box has-background-info-light">
      <div class="field">
        <label class="label">First Name</label>
        <div class="control">
          <input
            class="input"
            :class="{ 'is-danger': errors.indexOf('firstName') > -1}"
            v-model="contactInfo.firstName"
            type="text"
            placeholder="Enter first name"
          />
        </div>
        <p v-if="errors.indexOf('firstName') > -1" class="help is-danger">Please enter a first name.</p>
      </div>
      <div class="field">
        <label class="label">Last Name</label>
        <div class="control">
          <input
            class="input"
            :class="{ 'is-danger': errors.indexOf('lastName') > -1}"
            v-model="contactInfo.lastName"
            type="text"
            placeholder="Enter last name"
          />
        </div>
        <p v-if="errors.indexOf('lastName') > -1" class="help is-danger">Please enter a last name.</p>
      </div>
      <div class="field">
        <label class="label">Phone</label>
        <div class="control">
          <masked-input
            class="input"
            :class="{ 'is-danger': errors.indexOf('phone') > -1}"
            v-model="contactInfo.phone"
            type="text"
            placeholder="Enter phone"
            :mask="['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]"
            :guide="false"
          />
        </div>
        <p
          v-if="errors.indexOf('phone') > -1"
          class="help is-danger"
        >Please enter a valid phone number.</p>
      </div>
    </div>
  </div>
</template>

<script>
import MaskedInput from "vue-text-mask";

export default {
  name: "VolunteerContact",

  components: {
    MaskedInput
  },

  props: {
    volunteerContact: Object
  },

  data() {
    return {
      contactInfo: this.volunteerContact,
      errors: []
    };
  },

  watch: {
    "contactInfo.firstName": function() {
      this.validateFirstName();
      this.notifyChanges('firstName');
    },
    "contactInfo.lastName": function() {
      this.validateLastName();
      this.notifyChanges('lastName');
    },
    "contactInfo.phone": function() {
      this.validatePhone();
      this.notifyChanges('phone');
    },
  },

  methods: {
    clearPropError(propName) {
      let idx = this.errors.indexOf(propName);
      if (idx > -1) {
        this.errors.splice(idx, 1);
      }
    },

    validateProp(propName, validateFn) {
      this.clearPropError(propName);

      if (!validateFn(this.contactInfo[propName])) {
        this.errors.push(propName);
      }
    },

    validateFirstName() {
      this.validateProp("firstName", val => {
        return val != null && val.trim().length > 0;
      });
    },

    validateLastName() {
      this.validateProp("lastName", val => {
        return val != null && val.trim().length > 0;
      });
    },

    validatePhone() {
      this.validateProp("phone", val => {
        if (val == null || val.trim().length == 0) return false;

        const pattern = /^\d{10}$/;
        let transformedValue = val.replace(/[^\d]/g, "");
        return pattern.test(transformedValue);
      });
    },

    validateAll() {
      this.validateFirstName();
      this.validateLastName();
      this.validatePhone();

      return this.errors.length == 0;
    },

    notifyChanges(propName) {
      const value = this.contactInfo[propName];
      const hasErrors = this.errors.length > 0;
      this.$emit('contactinfochange', propName, value, hasErrors);
    },
  }
};
</script>