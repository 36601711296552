<template>
  <section class="hero is-link header-section">
    <div class="hero-body">
      <div class="container">
        <h1 class="title">Archangel Michael Church Simi Valley</h1>
        <h2 class="subtitle">{{ subtitle }}</h2>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    subtitle: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.header-section {
  margin-bottom: 20px;
}
</style>