<template>
  <div class="events-list-container">
    <p class="title is-3 has-text-grey">Select Days</p>

    <div>
      <div v-if="isLoading">Loading events..</div>
      <div v-else-if="events.length == 0">No events to display.</div>
      <div v-else>
        <div v-for="item in events" :key="item.id">
          <label class="checkbox">
            <input type="checkbox" :value="item.id" v-model="selectedEventIds"> <strong>{{ eventDate(item) }}</strong>, {{ eventStart(item) }} - {{ eventEnd(item) }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getEvents } from "../service/kidsClubData";
import { logError } from "../service/logger";

import mixins from "../mixins";

export default {
  name: "KidsClubEventList",

  mixins: [mixins],

  components: {
  },

  props: {
      selectedEvents: Array
  },

  data() {
    return {
      events: [],
      isLoading: true,
      selectedEventIds: this.selectedEvents.map(e => e.id),
    };
  },

  watch: {
    "selectedEventIds": function() {
      this.notifySelectionChanges();
    },
  },

  mounted() {
    getEvents(this.$route.params.eventTypeId)
      .then((result) => {
        this.events = result;
        this.isLoading = false;
      })
      .catch((err) => logError("getEvents Failed:", err));
  },

  methods: {
    notifySelectionChanges() {
      const selections = this.selectedEventIds.map(id => this.events.find(e => e.id === id));
      this.$emit('selectionchange', selections);
    },

    eventDate(eventObj) {
      return this.formatDay(eventObj.start);
    },

    eventStart(eventObj) {
      return this.formatTime(eventObj.start);
    },

    eventEnd(eventObj) {
      return this.formatTime(eventObj.end);
    },
  },
};
</script>

<style scoped>
.events-list-container {
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 2rem;
}
</style>