<template>
  <div>
    <article v-if="showConfirmation" class="message is-success">
      <div class="message-header">
        <p>Confirmation</p>
      </div>
      <div class="message-body">
        <div>
          You are confirmed to attend {{ selectedEventType.name }} on:
          <div v-for="event in selectedEvents" :key="event.id">
            <strong>{{ formatDate(event.date) }} ({{ event.description }})</strong>
          </div>
        </div>
      </div>
    </article>

    <h1 v-if="showConfirmation" class="title has-text-success">Confirmed!</h1>
    <h1 v-else class="title has-text-grey">Review And Confirm</h1>

    <read-only-field label="Name" :value="fullName" />
    <read-only-field label="Phone" :value="familyInfo.phone" />
    <read-only-field label="Number of People" :value="`${familyInfo.numberOfPeople}`" />
    <read-only-field v-if="false" label="Downstairs Okay?" :value="familyInfo.downstairsOk ? 'Yes' : 'No'" />
    <read-only-field label="Signing up For" :value="selectedEventType.name" />
    <read-only-field label="Date(s) Selected" :values="formattedSelectedEvents" />
  </div>
</template>

<script>
import mixins from "../mixins";
import ReadOnlyField from "./ReadOnlyField.vue";

export default {
  name: "ReviewAndSubmit",

  mixins: [mixins],

  components: {
    ReadOnlyField
  },

  props: {
    familyInfo: Object,
    selectedEvents: Array,
    selectedEventType: Object,
    showConfirmation: Boolean
  },

  computed: {
    formattedSelectedEvents() {
      const values = this.selectedEvents.map(selectedEvent => (
        `${this.formatDate(selectedEvent.date)} (${selectedEvent.description})`
      ));
      return values;
    },

    fullName() {
      return `${this.familyInfo.firstName} ${this.familyInfo.lastName}`;
    }
  }
};
</script>
