import moment from 'moment';

export default {
    methods: {
        formatDate(date) {
            return moment(date).format('dddd, MMMM Do YYYY');
        },

        formatDay(date) {
            return moment(date).format('dddd, MMM Do');
        },

        formatTime(date) {
            return moment(date).format('h:mm A');
        }
    }
}