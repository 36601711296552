<template>
  <div>
    <p class="title is-3 has-text-grey">Your Information</p>
    <div class="box has-background-info-light">
      <div class="field">
        <label class="label">First Name</label>
        <div class="control">
          <input
            class="input"
            :class="{ 'is-danger': errors.indexOf('firstName') > -1}"
            v-model="familyInfo.firstName"
            type="text"
            placeholder="Enter first name"
          />
        </div>
        <p v-if="errors.indexOf('firstName') > -1" class="help is-danger">Please enter a first name.</p>
      </div>
      <div class="field">
        <label class="label">Last Name</label>
        <div class="control">
          <input
            class="input"
            :class="{ 'is-danger': errors.indexOf('lastName') > -1}"
            v-model="familyInfo.lastName"
            type="text"
            placeholder="Enter last name"
          />
        </div>
        <p v-if="errors.indexOf('lastName') > -1" class="help is-danger">Please enter a last name.</p>
      </div>
      <div class="field">
        <label class="label">Phone</label>
        <div class="control">
          <masked-input
            class="input"
            :class="{ 'is-danger': errors.indexOf('phone') > -1}"
            v-model="familyInfo.phone"
            type="text"
            placeholder="Enter phone"
            :mask="['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]"
            :guide="false"
          />
        </div>
        <p
          v-if="errors.indexOf('phone') > -1"
          class="help is-danger"
        >Please enter a valid phone number.</p>
      </div>
      <div class="field">
        <label class="label">Number of Adults</label>
        <div class="control">
          <input
            class="input"
            :class="{ 'is-danger': errors.indexOf('numberOfAdults') > -1}"
            v-model.number="familyInfo.numberOfAdults"
            type="number"
          />
        </div>
        <p
          v-if="errors.indexOf('numberOfAdults') > -1"
          class="help is-danger"
        >Please enter a valid number.</p>
      </div>
      <div class="field">
        <label class="label">Number of Children</label>
        <div class="control">
          <input
            class="input"
            :class="{ 'is-danger': errors.indexOf('numberOfChildren') > -1}"
            v-model.number="familyInfo.numberOfChildren"
            type="number"
          />
        </div>
        <p
          v-if="errors.indexOf('numberOfChildren') > -1"
          class="help is-danger"
        >Please enter a number greater than zero.</p>
      </div>
    </div>
  </div>
</template>

<script>
import MaskedInput from "vue-text-mask";

export default {
  name: "KidsClubFamilyInfo",

  components: {
    MaskedInput
  },

  props: {
    familyInfoObj: Object
  },

  data() {
    return {
      familyInfo: this.familyInfoObj,
      errors: []
    };
  },

  watch: {
    "familyInfo.firstName": function() {
      this.validateFirstName();
      this.notifyChanges('firstName');
    },
    "familyInfo.lastName": function() {
      this.validateLastName();
      this.notifyChanges('lastName');
    },
    "familyInfo.phone": function() {
      this.validatePhone();
      this.notifyChanges('phone');
    },
    "familyInfo.numberOfAdults": function() {
      this.validateNumberOfAdults();
      this.notifyChanges('numberOfAdults');
    },
    "familyInfo.numberOfChildren": function() {
      this.validateNumberOfChildren();
      this.notifyChanges('numberOfChildren');
    },
  },

  methods: {
    clearPropError(propName) {
      let idx = this.errors.indexOf(propName);
      if (idx > -1) {
        this.errors.splice(idx, 1);
      }
    },

    validateProp(propName, validateFn) {
      this.clearPropError(propName);

      if (!validateFn(this.familyInfo[propName])) {
        this.errors.push(propName);
      }
    },

    validateFirstName() {
      this.validateProp("firstName", val => {
        return val != null && val.trim().length > 0;
      });
    },

    validateLastName() {
      this.validateProp("lastName", val => {
        return val != null && val.trim().length > 0;
      });
    },

    validatePhone() {
      this.validateProp("phone", val => {
        if (val == null || val.trim().length == 0) return false;

        const pattern = /^\d{10}$/;
        let transformedValue = val.replace(/[^\d]/g, "");
        return pattern.test(transformedValue);
      });
    },

    validateNumberOfAdults() {
      this.validateProp("numberOfAdults", val => {
        return (typeof val === 'number') && val >= 0;
      });
    },

    validateNumberOfChildren() {
      this.validateProp("numberOfChildren", val => {
        return (typeof val === 'number') && val > 0;
      });
    },

    validateAll() {
      this.validateFirstName();
      this.validateLastName();
      this.validatePhone();
      this.validateNumberOfAdults();
      this.validateNumberOfChildren();

      return this.errors.length == 0;
    },

    notifyChanges(propName) {
      const value = this.familyInfo[propName];
      const hasErrors = this.errors.length > 0;
      this.$emit('familyinfochange', propName, value, hasErrors);
    },
  }
};
</script>