import { initializeApp } from 'firebase/app';
import { getDatabase, ref, query, orderByChild, equalTo, onValue, push, set, update, serverTimestamp, off } from "firebase/database";

import { log } from './logger';

let dbUrl = '';

if (process.env.NODE_ENV === 'development') {
    dbUrl = "https://amcsv-test.firebaseio.com";
} else {
    dbUrl = "https://amcsv-2c3f2.firebaseio.com";
}

const firebaseConfig = {
    apiKey: "AIzaSyCTpudld5U4OxDyP5MPdg-JKzkm0oYlaII",
    authDomain: "amcsv-2c3f2.firebaseapp.com",
    databaseURL: dbUrl,
    projectId: "amcsv-2c3f2",
    storageBucket: "amcsv-2c3f2.appspot.com",
    messagingSenderId: "683832146174",
    appId: "1:683832146174:web:a5908efc22022fcccbcdaf"
};

const app = initializeApp(firebaseConfig);

const database = getDatabase(app);

export function getEventDetails(eventId) {
    const dbPath = `volunteer_events/${eventId}`;
    const volunteerJobsRef = ref(database, dbPath);

    return new Promise((resolve, reject) => {
        const callback = (snapshot) => {
            const volunteerEventObj = snapshot.val();
            volunteerEventObj.id = snapshot.key;
            resolve(volunteerEventObj);
        };

        const errorCallback = (error) => (reject(error));

        const options = { onlyOnce: true };

        onValue(volunteerJobsRef, callback, errorCallback, options);
    });
}

export function getJobs(eventId) {
    const dbPath = 'volunteer_jobs';
    const volunteerJobsRef = query(ref(database, dbPath), orderByChild('eventId'), equalTo(eventId));

    return new Promise((resolve, reject) => {
        const callback = (snapshot) => {
            let volunteerJobs = [];
            snapshot.forEach(childSnapshot => {
                let volunteerJobObj = childSnapshot.val();
                volunteerJobObj.id = childSnapshot.key;
                volunteerJobs.push(volunteerJobObj);
            });
            resolve(volunteerJobs);
        };

        const errorCallback = (error) => (reject(error));

        const options = { onlyOnce: true };

        onValue(volunteerJobsRef, callback, errorCallback, options);
    });
}

export function registerVolunteerCountListener(jobId, slotId, listener) {
    log('Registering volunteer count listener..');
    const dbPath = `volunteer_jobs/${jobId}/slots/${slotId}/volunteers`;
    const volunteerRef = ref(database, dbPath);

    const callback = (snapshot) => {
        const volunteers = snapshot.val();
        let count = 0;
        if (volunteers) {
            count = Object.keys(volunteers).length;
        }
        listener(count);
    };

    onValue(volunteerRef, callback);
}

export function deregisterVolunteerCountListener(jobId, slotId) {
    log('Deregistering volunteer count listener..');
    const dbPath = `volunteer_jobs/${jobId}/slots/${slotId}/volunteers`;
    const volunteerRef = ref(database, dbPath);

    off(volunteerRef);
}

export function signUp(volunteerData) {
    const {contact:volunteerInfo, selections} = volunteerData;

    return getOrAddVolunteerId(volunteerInfo)
        .then(volunteerId => {
            const updates = {};
            selections.forEach(sel => {
                updates[`volunteer_jobs/${sel.jobId}/slots/${sel.slotId}/volunteers/${volunteerId}`] = serverTimestamp();
            });
            log('Sending updates..');

            return update(ref(database), updates);
        });
}

/* helpers */

const getOrAddVolunteerId = (volunteerInfo) => {
    const phoneNumsOnly = volunteerInfo.phone.replace(/[^\d]/g, '');
    return getPhoneIndex(phoneNumsOnly)
        .then(volunteerId => {
            if (volunteerId == null) {
                return addVolunteer(volunteerInfo).then(id => {
                    return addPhoneIndex(phoneNumsOnly, id).then(() => id);
                });
            } else {
                return volunteerId;
            }
        });
};

const getPhoneIndex = (normalizedPhoneNum) => {
    log('Checking phone index..');
    const phoneIndexRef = ref(database, `volunteer_phone_index/${normalizedPhoneNum}`);

    return new Promise((resolve, reject) => {
        const callback = piSnapshot => resolve(piSnapshot.val());

        const options = { onlyOnce: true };

        onValue(phoneIndexRef, callback, (err) => reject(err), options);
    });
};

const addPhoneIndex = (normalizedPhoneNum, volunteerId) => {
    log('Adding phone index..');
    const phoneIndexRef = ref(database, `volunteer_phone_index/${normalizedPhoneNum}`);
    return set(phoneIndexRef, volunteerId);
};

const addVolunteer = (volunteerInfo) => {
    log('Adding volunteer..');
    const fullName = `${volunteerInfo.firstName} ${volunteerInfo.lastName}`;
    const volunteersRef = ref(database, 'volunteers');
    return push(volunteersRef, { phone: volunteerInfo.phone, name: fullName })
        .then(volSnapshot => volSnapshot.key);
};
