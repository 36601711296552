<template>
  <div>
    <header-section subtitle="Church Signup"></header-section>

    <section class="wizard">
      <div class="container">
        <div v-if="errorMsg != null" class="notification is-danger is-light">{{ errorMsg }}</div>
        <div v-if="successMsg != null" class="notification is-success is-light">{{ successMsg }}</div>

        <div v-if="stepIdx == 1">
          <article class="message is-danger">
            <div class="message-header">
              <p>Instructions</p>
            </div>
            <div class="message-body">
              <p>
                Please sign up for ONLY ONE liturgy within a 30-day period. If you wish to attend additional
                liturgies, please wait until the day before a specific liturgy and sign up for any
                remaining space. Liturgies are usually on Wednesday, Friday, Saturday, and Sunday.
              </p>
            </div>
          </article>
          <family-info
            :family-info="familyInfo"
            @downstairsselectionchange="updateDownstairsSelection"
            ref="familyInputForm"
          />
          <event-type-selection
            v-if="!isLoadingEventTypes"
            :event-types="eventTypes"
            :selected-event-type="selectedEventType"
            @eventtypechange="updateEventType"
          />
        </div>

        <div v-if="stepIdx == 2">
          <event-selection
            :selected-event-type="selectedEventType"
            :selected-events="selectedEvents"
            :number-of-people="familyInfo.numberOfPeople"
            :downstairs-ok="familyInfo.downstairsOk"
            :multiSelectEnabled="Boolean(selectedEventType.multiSelectEnabled)"
            v-on:eventselect="selectEvent"
            v-on:eventdeselect="deselectEvent"
          />
        </div>

        <div v-if="stepIdx == 3">
          <review-and-submit
            :family-info="familyInfo"
            :selected-events="selectedEvents"
            :selected-event-type="selectedEventType"
            :show-confirmation="done === true"
          />
        </div>

        <step-navigation
          v-if="done === false"
          :show-previous-button="stepIdx > 1"
          :show-next-button="(stepIdx == 2) ? (selectedEvents.length > 0) : (stepIdx < 3)"
          :show-submit-button="stepIdx == 3"
          v-on:nextstep="changeSteps(1)"
          v-on:previousstep="changeSteps(-1)"
          v-on:laststepsubmitted="submitReservation"
        />

        <div v-if="isSavingReservation" class="modal is-active">
          <div class="modal-background"></div>
          <div class="modal-content">
            <div class="box">
              <p class="title is-6">
                Saving your information..
                <span class="loader"></span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { getEventTypes } from "../service/data";
import { finalizeReservation, NO_CAPACITY_ERROR } from "../service/logic";

import HeaderSection from "./Header.vue";
import EventTypeSelection from "./EventTypeSelection.vue";
import FamilyInfo from "./FamilyInfo.vue";
import StepNavigation from "./StepNavigation.vue";
import EventSelection from "./EventSelection.vue";
import ReviewAndSubmit from "./ReviewAndSubmit.vue";

export default {
  name: "App",

  components: {
    HeaderSection,
    EventTypeSelection,
    FamilyInfo,
    StepNavigation,
    EventSelection,
    ReviewAndSubmit
  },

  data() {
    return {
      stepIdx: 1,
      eventTypes: [],
      isLoadingEventTypes: true,
      isSavingReservation: false,
      selectedEventType: null,
      selectedEvents: [],
      familyInfo: {
        firstName: "",
        lastName: "",
        phone: "",
        numberOfPeople: 1,
        downstairsOk: false
      },
      errorMsg: null,
      successMsg: null,
      done: false
    };
  },

  methods: {
    updateDownstairsSelection(value) {
      this.familyInfo.downstairsOk = value;
    },

    updateEventType(eventType) {
      if (eventType != this.selectedEventType) {
        this.selectedEventType = eventType;
        this.selectedEvents = [];
      }
    },

    selectEvent(event) {
      if (this.selectedEventType.multiSelectEnabled) {
        this.selectedEvents.push(event);
      } else {
        this.selectedEvents = [event];
        this.changeSteps(1);
      }
    },

    deselectEvent(event) {
      const idx = this.selectedEvents.findIndex(e => e.id == event.id);
      if (idx > -1) {
        this.selectedEvents.splice(idx, 1);
      }
    },

    changeSteps(delta) {
      this.errorMsg = null;
      if (this.stepIdx > 1 || this.$refs.familyInputForm.validateAll()) {
        this.stepIdx += delta;
        window.scrollTo(0, 150);
      }
    },

    spaceNoLongerAvailable() {
      this.setMessage(
        "Sorry, the date you selected no longer has availability. Please select a different date."
      );
      this.stepIdx = 2;
      this.selectedEvents = [];
    },

    setMessage(msg, isError = true) {
      if (isError) {
        this.errorMsg = msg;
      } else {
        this.successMsg = msg;
      }
      window.scrollTo(0, 150);
    },

    submitReservation() {
      this.isSavingReservation = true;

      // Submit first one separately to avoid race condition when ceating family info
      finalizeReservation(this.selectedEvents[0], this.selectedEventType, this.familyInfo)
        .then(() => {
          const promises = this.selectedEvents.slice(1).map((selectedEvent) => {
            return finalizeReservation(selectedEvent, this.selectedEventType, this.familyInfo);
          });
          return Promise.all(promises);
        })
        .then(() => {
          this.isSavingReservation = false;
          this.done = true;
          window.scrollTo(0, 150);
        })
        .catch((e) => {
          this.isSavingReservation = false;
          if (e === NO_CAPACITY_ERROR) {
            this.spaceNoLongerAvailable();
          } else {
            this.setMessage(
              "Sorry, we encountered an error while saving your information."
            );
          }
        });
    }
  },

  mounted() {
    getEventTypes().then(result => {
      this.eventTypes = result;
      if (this.eventTypes.length > 0) {
        this.selectedEventType = this.eventTypes[0];
      }
      this.isLoadingEventTypes = false;
    });
  }
};
</script>

<style>
.container {
  max-width: 800px;
  padding: 0 15px;
}
.wizard {
  margin-top: 10px;
}
.loader {
  display: inline-block;
}
</style>
