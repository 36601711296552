<template>
  <div class="jobs-list-container">
    <p class="title is-3 has-text-grey">Volunteer Jobs</p>

    <article class="message is-warning">
        <div class="message-body">
        Make your selections then click <strong>Continue</strong> at the bottom of the page.
        </div>
    </article>

    <div>
      <div v-if="isLoading">Loading jobs..</div>
      <div v-else-if="jobs.length == 0">No jobs to display.</div>
      <div v-else>
        <div class="box has-background-info-light" v-for="item in jobs" :key="item.id">
            <p class="title is-4">{{ item.title }}</p>
          <div class="level">
            <div class="level-item" v-for="(slot, slotId) in item.slots" :key="slotId">
              <volunteer-job
                :jobId="item.id"
                :jobTitle="item.title"
                :slotId="slotId"
                :slotData="slot"
                :isSelected="isSelected(item.id, slotId)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getJobs } from "../service/volunteerData";
import { logError } from "../service/logger";

import VolunteerJob from "./VolunteerJob.vue";

export default {
  name: "VolunteerJobsList",

  components: {
    VolunteerJob,
  },

  props: {
      selections: Array
  },

  data() {
    return {
      jobs: [],
      isLoading: true,
    };
  },

  mounted() {
    getJobs(this.$route.params.eventId)
      .then((result) => {
        this.jobs = result;
        this.isLoading = false;
      })
      .catch((err) => logError("getJobs Failed:", err));
  },

  methods: {
    isSelected(jobId, slotId) {
      let existingIndex = this.selections.findIndex(s => s.jobId === jobId && s.slotId === slotId);
      return existingIndex > -1;
    }
  },
};
</script>

<style scoped>
.jobs-list-container {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>