<template>
  <div class="field is-horizontal">
    <div class="field-label is-normal">
      <label class="label">{{ label }}</label>
    </div>
    <div class="field-body">
      <div class="field">
        <p class="control">
          <input v-for="(value, idx) in inputValues" :key="idx" class="input is-normal is-static" :value="value" readonly />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    value: String,
    values: Array
  },

  computed: {
    inputValues() {
      return this.value ? [this.value] : this.values;
    }
  }
};
</script>

<style scoped>
.field-label {
  padding-top: 0.5em !important;
  flex-grow: 2 !important;
}
</style>