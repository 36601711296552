<template>
  <div class="field is-grouped is-grouped-right step-navigation">
    <div v-if="showPreviousButton" class="control">
      <button class="button is-link is-light" @click="$emit('previousstep')">Back</button>
    </div>
    <div v-if="showNextButton" class="control">
      <button class="button is-link" @click="$emit('nextstep')">Next</button>
    </div>
    <div v-if="showSubmitButton" class="control">
      <button class="button is-link" @click="$emit('laststepsubmitted')">Submit</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showPreviousButton: {
        type: Boolean,
        default: false
    },
    showNextButton: {
        type: Boolean,
        default: true
    },
    showSubmitButton: {
        type: Boolean,
        default: false
    }
  }
};
</script>

<style>
.step-navigation {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>